import React from 'react'
import TopBar from '../../components/TopBar/Navbar';

export const Profile = () => {
  return (
    <div>
      <TopBar/>
        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perferendis tenetur doloremque, minima eveniet quisquam eaque assumenda eius suscipit facere aliquam mollitia dignissimos temporibus, qui sunt iure dolor nobis quo saepe!</p>
    </div>
  )
}

export default Profile;
